// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.z3MhvwLDQ8DNiWP3YFXt {
    position: fixed;
    top: -100vh;
    right: 0;
    width: 30vw;
    height: 100vh;
    z-index: 100;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    background-color: rgba(255, 255, 255, 0.87);
    -webkit-backdrop-filter: blur(5px);
    -ms-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

    transition: all 0.5s ease-in-out;
}

.z3MhvwLDQ8DNiWP3YFXt:hover {
    top: 0;
    /* border-radius: 0; */
}

.z3MhvwLDQ8DNiWP3YFXt a {
    text-decoration: none;
    text-align: end;
    color: rgb(26, 26, 26);

    width: 80%;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.z3MhvwLDQ8DNiWP3YFXt img {
    width: 7rem;
}

.klONXVipLJKXAiDby_iM {
    width: 2.8rem;
    height: 2.65rem;

    border-radius: 0 0 7px 7px;

    position:absolute;
    top: 100%;
    left:0;

    background-color: rgba(255, 255, 255, 0.87);
    backdrop-filter: blur(10px);

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.klONXVipLJKXAiDby_iM svg {
    height: 1.8rem;
    width: 1.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/js/VaccineStock/App/Menu/Menu.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,QAAQ;IACR,WAAW;IACX,aAAa;IACb,YAAY;;IAEZ,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;;IAEnB,2CAA2C;IAC3C,kCAAkC;IAClC,+BAA+B;IAC/B,2BAA2B;;IAE3B,gCAAgC;AACpC;;AAEA;IACI,MAAM;IACN,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,sBAAsB;;IAEtB,UAAU;;IAEV,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,eAAe;;IAEf,0BAA0B;;IAE1B,iBAAiB;IACjB,SAAS;IACT,MAAM;;IAEN,2CAA2C;IAC3C,2BAA2B;;IAE3B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,aAAa;AACjB","sourcesContent":[".menu {\n    position: fixed;\n    top: -100vh;\n    right: 0;\n    width: 30vw;\n    height: 100vh;\n    z-index: 100;\n\n    display: flex;\n    flex-direction: column;\n    justify-content: space-evenly;\n    align-items: center;\n\n    background-color: rgba(255, 255, 255, 0.87);\n    -webkit-backdrop-filter: blur(5px);\n    -ms-backdrop-filter: blur(10px);\n    backdrop-filter: blur(10px);\n\n    transition: all 0.5s ease-in-out;\n}\n\n.menu:hover {\n    top: 0;\n    /* border-radius: 0; */\n}\n\n.menu a {\n    text-decoration: none;\n    text-align: end;\n    color: rgb(26, 26, 26);\n\n    width: 80%;\n    \n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.menu img {\n    width: 7rem;\n}\n\n.menu-switch {\n    width: 2.8rem;\n    height: 2.65rem;\n\n    border-radius: 0 0 7px 7px;\n\n    position:absolute;\n    top: 100%;\n    left:0;\n\n    background-color: rgba(255, 255, 255, 0.87);\n    backdrop-filter: blur(10px);\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    align-content: center;\n}\n\n.menu-switch svg {\n    height: 1.8rem;\n    width: 1.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `z3MhvwLDQ8DNiWP3YFXt`,
	"menu-switch": `klONXVipLJKXAiDby_iM`
};
export default ___CSS_LOADER_EXPORT___;
